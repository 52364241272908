import React, { useState, useEffect } from "react";
import subwebCoreApi from "../../config/SubwebCoreApi";
import CoreBackendApi from "../../config/CoreBackendApi";
import configApi from "../../config/SquidAuth";
import LocalApi from "../../config/LocalApi";
import { subBaseUrl } from "../../config/Environment";
import EmailPagePresentation from "./EmailPage.presentation";

const EmailPageContainer = () => {
  const [apps, setApps] = useState([]);
  const [loadingApps, setLoadingApps] = useState(false);
  const [warningMessageApps, setWarningMessageApps] = useState(undefined);
  const [selectedApp, setSelectedApp] = useState(undefined);
  const [users, setUsers] = useState([]);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [warningMessageUsers, setWarningMessageUsers] = useState(undefined);
  const [emailMessage, setEmailMessage] = useState("");
  const [emailSubject, setEmailSubject] = useState("");
  const [recipients, setRecipients] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [appRoles, setAppRoles] = useState([]);
  const [loadingAppRoles, setLoadingAppRoles] = useState(false);
  const [warningMessageAppRoles, setWarningMessageAppRoles] =
    useState(undefined);
  const [selectedAppRole, setSelectedAppRole] = useState(undefined);
  const [warningMessageEmail, setWarningMessageEmail] = useState(undefined);
  const [loadingEmail, setLoadingEmail] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);

  const getAllApps = async () => {
    setLoadingApps(true);
    const rawApps = [];

    try {
      await subwebCoreApi.listAllApplications((data) => rawApps.push(...data));
    } catch (error) {
      setWarningMessageApps("Erro ao carregar as aplicações.");
    } finally {
      setLoadingApps(false);
    }

    const appDocs = rawApps
      .filter((rawApp) => rawApp.documentations.length > 0)
      .map((rawApp) => {
        const doc = rawApp.documentations[0];

        return {
          // id: doc?.documentationId,
          id: rawApp.applicationId,
          key: rawApp.slug,
          name: rawApp.name,
          type: doc?.documentationType,
          url: `${subBaseUrl}${doc?.url}`,
        };
      })
      .sort((a, b) => {
        return a.name.localeCompare(b.name, "en", { sensitivity: "base" });
      });

    const uniqueAppDocs = appDocs.reduce((acc, curr) => {
      if (!acc.find((app) => app.key === curr.key)) {
        acc.push(curr);
      }
      return acc;
    }, []);

    setApps(uniqueAppDocs);
  };

  function resetStates() {
    setAppRoles([]);
    setSelectedAppRole(undefined);
    setSelectedApp(undefined);
    setSearchQuery("");
    setEmailMessage("");
    setEmailSubject("");
    setRecipients([]);
    getAllUsers();
  }

  const handleSendEmail = async () => {
    setLoadingEmail(true);
    let me;
    const token = LocalApi.getToken();
    try {
      me = await CoreBackendApi.fetchMe(token);
      await subwebCoreApi.sendEmail(
        emailSubject,
        emailMessage,
        recipients,
        (error) => setWarningMessageEmail(`Erro ao enviar o email. ${error}`),
        me.email
      );
      resetStates();
      setShowEmailModal(true);
    } catch (error) {
      setWarningMessageEmail("Erro ao enviar o email.");
    } finally {
      setLoadingEmail(false);
    }
  };

  const getAllUsers = async () => {
    setLoadingUsers(true);
    const rawUsers = [];

    try {
      const data = await CoreBackendApi.listUsers();
      rawUsers.push(
        ...(data
          .filter((user) => user.email)
          .sort((a, b) => {
            return a.name.localeCompare(b.name, "en", { sensitivity: "base" });
          }) ?? [])
      );
    } catch (error) {
      setWarningMessageUsers("Erro ao carregar os usuários.");
    } finally {
      setLoadingUsers(false);
    }

    setUsers(rawUsers);
  };

  const getUsersByRole = async (role) => {
    setLoadingUsers(true);
    const rawUsers = [];

    try {
      await configApi.listUsersByRole(role?.id, (data) =>
        rawUsers.push(
          ...(data
            .filter((user) => user.email)
            .sort((a, b) => {
              return a.name.localeCompare(b.name, "en", {
                sensitivity: "base",
              });
            }) ?? [])
        )
      );
    } catch (error) {
      setWarningMessageUsers(
        `Erro ao carregar os usuários com a função ${role?.name} na aplicação ${selectedApp?.name}.`
      );
    } finally {
      setLoadingUsers(false);
    }

    setUsers(rawUsers);
  };

  const getAppRoles = async (app) => {
    setLoadingAppRoles(true);
    const rawRoles = [];

    try {
      await configApi.getAppRoles(app?.id, (data) => rawRoles.push(...data));
    } catch (error) {
      setWarningMessageAppRoles(
        `Erro ao carregar as funções de usuário da aplicação ${app?.name}.`
      );
    } finally {
      setLoadingAppRoles(false);
    }
    setAppRoles(rawRoles);
  };

  useEffect(() => {
    getAllApps();
    getAllUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <EmailPagePresentation
      apps={apps}
      loadingApps={loadingApps}
      warningMessage={
        warningMessageUsers ||
        warningMessageApps ||
        warningMessageAppRoles ||
        warningMessageEmail
      }
      onAcceptWarning={() => {
        setWarningMessageApps(undefined);
        setWarningMessageUsers(undefined);
        setWarningMessageAppRoles(undefined);
        setWarningMessageEmail(undefined);
      }}
      users={users}
      loadingUsers={loadingUsers}
      recipients={recipients}
      setRecipients={setRecipients}
      searchQuery={searchQuery}
      setSearchQuery={setSearchQuery}
      selectedApp={selectedApp}
      setSelectedApp={setSelectedApp}
      getAppRoles={getAppRoles}
      appRoles={appRoles}
      loadingAppRoles={loadingAppRoles}
      selectedAppRole={selectedAppRole}
      setSelectedAppRole={setSelectedAppRole}
      getUsersByRole={getUsersByRole}
      getAllUsers={getAllUsers}
      emailMessage={emailMessage}
      setEmailMessage={setEmailMessage}
      emailSubject={emailSubject}
      setEmailSubject={setEmailSubject}
      handleSendEmail={handleSendEmail}
      loadingEmail={loadingEmail}
      showEmailModal={showEmailModal}
      setShowEmailModal={setShowEmailModal}
      resetStates={resetStates}
    />
  );
};

export default EmailPageContainer;

import axios from "axios";
import LocalApi from "./LocalApi";
import { subBaseUrl } from "./Environment";

class SubwebCoreApi {
  constructor() {
    this.baseurl = `${subBaseUrl}/app/core/api`;
    this.errorCallback = undefined;
  }

  mountConfig = () => {
    const token = LocalApi.getToken();
    if (token) {
      return {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
    }
    return {};
  };

  setErrorCallback = (errorCallback) => {
    this.errorCallback = errorCallback;
  };

  processError = (err) => {
    if (this.errorCallback) {
      this.errorCallback(err);
      this.errorCallback = undefined;
    }
  };

  // SUBWEB CORE API METHODS
  listAllApplications = async (setCallback) => {
    await axios
      .get(`${this.baseurl}/application/`, this.mountConfig())
      .then((response) => {
        if (response.status === 200) {
          setCallback(response.data);
        }
      })
      .catch(this.processError);
  };

  getApplication = async (applicationId, setCallback) => {
    await axios
      .get(`${this.baseurl}/application/${applicationId}`, this.mountConfig())
      .then((response) => {
        if (response.status === 200) {
          setCallback(response.data);
        }
      })
      .catch(this.processError);
  };

  getApplicationVersions = async (applicationId, setCallback) => {
    await axios
      .get(
        `${this.baseurl}/application/${applicationId}/versions-changelog/`,
        this.mountConfig()
      )
      .then((response) => {
        if (response.status === 200) {
          setCallback(response.data);
        }
      })
      .catch(this.processError);
  };

  filterNotifications = async (filters, setCallback, setLoading) => {
    if (setLoading) setLoading(true);
    await axios({
      method: "POST",
      url: `${this.baseurl}/inbox/filter`,
      ...this.mountConfig(),
      data: filters,
    })
      .then((response) => {
        if (response.status === 200) {
          setCallback(response.data);
        }
      })
      .catch(this.processError)
      .finally(() => {
        if (setLoading) setLoading(false);
      });
  };

  getNotificationsCounts = async (setCallback, setLoading) => {
    if (setLoading) setLoading(true);
    await axios({
      method: "GET",
      url: `${this.baseurl}/inbox/counts`,
      ...this.mountConfig(),
    })
      .then((response) => {
        if (response.status === 200) {
          setCallback(response.data);
        }
      })
      .catch(this.processError)
      .finally(() => {
        if (setLoading) setLoading(false);
      });
  };

  markNotificationAsRead = async (notificationId, setCallback, setLoading) => {
    if (setLoading) setLoading(true);
    await axios({
      method: "PATCH",
      url: `${this.baseurl}/notification/${notificationId}/read`,
      ...this.mountConfig(),
    })
      .then((response) => {
        if (response.status === 200) {
          setCallback(response.data);
        }
      })
      .catch(this.processError)
      .finally(() => {
        if (setLoading) setLoading(false);
      });
  };

  markNotificationAsUnread = async (
    notificationId,
    setCallback,
    setLoading
  ) => {
    if (setLoading) setLoading(true);
    await axios({
      method: "PATCH",
      url: `${this.baseurl}/notification/${notificationId}/unread`,
      ...this.mountConfig(),
    })
      .then((response) => {
        if (response.status === 200) {
          setCallback(response.data);
        }
      })
      .catch(this.processError)
      .finally(() => {
        if (setLoading) setLoading(false);
      });
  };

  deleteNotification = async (notificationId, setCallback, setLoading) => {
    if (setLoading) setLoading(true);
    await axios({
      method: "DELETE",
      url: `${this.baseurl}/notification/${notificationId}`,
      ...this.mountConfig(),
    })
      .then((response) => {
        if (response.status === 200) {
          setCallback(response.data);
        }
      })
      .catch(this.processError)
      .finally(() => {
        if (setLoading) setLoading(false);
      });
  };

  requestApplicationAccess = async (
    applicationId,
    petrobrasKey,
    setCallback
  ) => {
    await axios({
      method: "POST",
      url: `${this.baseurl}/application/${applicationId}/access/request`,
      ...this.mountConfig(),
      data: {
        petrobrasKey,
        reason: "Não especificado",
      },
    })
      .then((response) => {
        if (response.status === 200) {
          setCallback(response.data);
        }
      })
      .catch(this.processError);
  };

  requestPortalAccess = async (petrobrasKey, setCb, errorCb, finallyCb) => {
    await axios({
      method: "POST",
      url: `${this.baseurl}/portal/access/request`,
      ...this.mountConfig(),
      data: {
        petrobrasKey,
        reason: "Não especificado",
      },
    })
      .then((response) => {
        if (response.status === 200) {
          setCb(response.data);
        }
      })
      .catch(errorCb)
      .finally(finallyCb);
  };

  authorizeApplicationAccess = async (
    applicationId,
    petrobrasKey,
    grant,
    setCallback,
    errorCallback
  ) => {
    await axios({
      method: "POST",
      url: `${this.baseurl}/application/${applicationId}/access/authorize`,
      ...this.mountConfig(),
      data: {
        petrobrasKey,
        grant,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          setCallback(response.data);
        }
      })
      .catch(errorCallback);
  };

  authorizePortalAccess = async (
    petrobrasKey,
    grant,
    setCallback,
    errorCallback
  ) => {
    await axios({
      method: "POST",
      url: `${this.baseurl}/portal/access/authorize`,
      ...this.mountConfig(),
      data: {
        petrobrasKey,
        grant,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          setCallback(response.data);
        }
      })
      .catch(errorCallback);
  };

  listApplicationAccessRequests = async (
    applicationId,
    setCallback,
    errorCallback
  ) => {
    await axios({
      method: "GET",
      url: `${this.baseurl}/application/${applicationId}/access/request`,
      ...this.mountConfig(),
    })
      .then((response) => {
        if (response.status === 200) {
          setCallback(response.data);
        }
      })
      .catch(errorCallback);
  };

  listPortalAccessRequests = async (setCallback, errorCallback) => {
    await axios({
      method: "GET",
      url: `${this.baseurl}/portal/access/request`,
      ...this.mountConfig(),
    })
      .then((response) => {
        if (response.status === 200) {
          setCallback(response.data);
        }
      })
      .catch(errorCallback);
  };

  deleteAccessRequest = async (accessRequestId, setCb, errorCb) => {
    await axios({
      method: "DELETE",
      url: `${this.baseurl}/access-request/${accessRequestId}`,
      ...this.mountConfig(),
    })
      .then((response) => {
        if (response.status === 200) {
          setCb(response.data);
        }
      })
      .catch(errorCb);
  };

  sendEmail = async (
    emailSubject,
    emailMessage,
    recipients,
    errorCallback,
    sender
  ) => {
    await axios({
      method: "POST",
      url: `${this.baseurl}/email/`,
      ...this.mountConfig(),
      data: {
        message: {
          body: {
            content: emailMessage,
            contentType: "text",
          },
          sender: {
            emailAddress: {
              address: sender,
            },
          },
          subject: emailSubject,
          toRecipients: recipients?.map((recipient) => {
            return {
              emailAddress: {
                address: recipient.email,
              },
            };
          }),
        },
        nivelProtecao: "PUBLICA",
      },
    }).catch(errorCallback);
  };
}

const subwebCoreApi = new SubwebCoreApi();

export default subwebCoreApi;
